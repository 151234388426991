import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
// import * as ROUTES from '../components/constants/routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/style.scss';
import Top from '../components/Top';
// import FAQ from '../components/FAQ';
import * as ROUTES from '../constants/routes';
// const LoadableCallendar = Loadable({
//   loader: () => import('../components/Calendar'),
//   loading() {
//     return <div>Loading...</div>
//   },
// })


const LandingPage = () => (
  <Fragment>
  <title>weekup 高校部活動専門ホームページ制作</title>
  <script src="path/to/intersection-observer.js"></script>
<meta name="description" content=" 高校部活動専門ホームページ制作サービス。部活動の魅力や熱量を届けます！まずはお問い合わせください！"/>
<Top />
  </Fragment>
);

export default () => (
  <Layout>
    <LandingPage />
  </Layout>
);
