import React from 'react';
import { Link } from 'gatsby';
import main from '../../images/main/main.png';
import line from '../../images/main/line.png';
import logo from '../../images/logo.png';
import top from '../../images/main/top.png';
import topM from '../../images/main/top-mobile.png';
import hearing from '../../images/main/hearing.png';
import hearingM from '../../images/main/hearing-mobile.png';
import design from '../../images/main/design.png';
import designM from '../../images/main/design-mobile.png';
import programming from '../../images/main/programming.png';
import programmingM from '../../images/main/programming-mobile.png';
import complete from '../../images/main/complete.png';
import completeM from '../../images/main/complete-mobile.png';
import kugayamabbc from '../../images/main/kugayama-bbc.png';
import kugayamargb from '../../images/main/kugayama-rgb.png';
import teikyobbc from '../../images/main/teikyo-bbc.png';
import * as FadeIn from "../../components/fadeIn";
import * as Line from "../../components/fadeIn";
import * as Text from "../../components/fadeIn";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        device: 'pc'
    };
  };
  componentDidMount() {
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
        this.setState({ device: 'sp' });
    } else {
        this.setState({ device: 'pc' });
    }
  }
contact = ()=> {
    window.open('https://lin.ee/Do3DuFo', "_blank");
  }
  render() {
    return (
      <div>

      <div className="main-content">
      <img className="main-image" src={main} />
      <div className="main-section">
      <div className="main-text">かけがえのない、3年間を"照らす"</div>
      <div className="message-line">
      <a href="https://lin.ee/Do3DuFo" class="btn btn-c btn--yellow btn--cubic"><img className="top-line" alt="line" src={line} />ご相談はこちらから
      </a>
      </div>
      </div>
      </div>
      <FadeIn.Up>
      <div className="about-section">About Us</div>
      <div className="about-text">
      <div>映画スタジオには作品作りの専門家が揃っている様に、</div>
      <div>HighFive Studioには、プロデューサー、デザイナー、エンジニアが所属し、</div>
      <div>主役である部活動にスポットライトを当て、輝かせるホームページを制作。</div>
      </div>
      </FadeIn.Up>

      <FadeIn.Up>
      <div className="top-section">
      {this.state.device =="sp" && (
      <img className="top-page" src={topM} />
      )}
      {this.state.device =="pc" && (
      <img className="top-page" src={top} />
      )}
      <div className="top-text">
      <div className="top-main">ホームページができるまで</div>
      <div className="top-sub">最高のスタジオをお届けします。</div>
      </div>
      </div>
      </FadeIn.Up>

      <div className="section-page">
      <div className="section-title">
      <div className="number-title">01</div>
      <div className="text-title">ヒアリング</div>
      </div>
      <FadeIn.Up>
      <div className="section-main">
      <Line.Up>
      <div className="line-action"></div>
      </Line.Up>
      <Text.Left>
      <div className="line-text">80%の保護者が高校選びに部活動を検索しています</div>
      </Text.Left>
      {this.state.device =="sp" && (
      <img className="section-image" src={hearingM} />
      )}
      {this.state.device =="pc" && (
      <img className="section-image" src={hearing} />
      )}
      <div className="section-content">
      <div className="section-flex">
      <div className="section-braket1">「</div>
      <div className="section-text">
      <div className="section-text1">ホームページづくりの</div>
      <div className="section-text2">第一歩はヒアリングから。</div>
      </div>
      <div className="section-braket2">」</div>
      </div>
      </div>
      <Line.Down>
      <div className="line-action-down"></div>
      </Line.Down>
      <Text.Left>
      <div className="line-text-down">最高のホームページを作るために部活動の状況について質問させてください</div>
      </Text.Left>
      </div>
      </FadeIn.Up>
      </div>



      <div className="section-page-2">
      <div className="section-title">
      <div className="number-title">02</div>
      <div className="text-title-2">制作</div>
      </div>
      <div className="catch">デザイン</div>

      <FadeIn.Up>
      <div className="section-main">
      <Line.Up>
      <div className="line-action-design"></div>
      </Line.Up>
      <Text.Left>
      <div className="line-text-design">デザイナーがサイトの見た目を考案</div>
      </Text.Left>
      {this.state.device =="sp" && (
      <img className="section-image" src={designM} />
      )}
      {this.state.device =="pc" && (
      <img className="section-image" src={design} />
      )}
      <div className="section-content">
      <div className="section-flex">
      <div className="section-braket1">「</div>
      <div className="section-text">
      <div className="section-text1">全てをかっこいいデザインへ。</div>
      </div>
      <div className="section-braket2">」</div>
      </div>
      </div>
      <Line.Down>
      <div className="line-action-down-design"></div>
      </Line.Down>
      <Text.Left>
      <div className="line-text-down-design">高品質カメラで選手や体育館の写真を撮影</div>
      </Text.Left>
      </div>
      </FadeIn.Up>

      <div className="catch-programming">プログラミング</div>

      <FadeIn.Up>
      <div className="section-main">
      <Line.Up>
      <div className="line-action"></div>
      </Line.Up>
      <Text.Left>
      <div className="line-text">外資系IT企業勤務の一流エンジニアにお任せあれ</div>
      </Text.Left>
      {this.state.device =="sp" && (
      <img className="section-image" src={programmingM} />
      )}
      {this.state.device =="pc" && (
      <img className="section-image" src={programming} />
      )}
      <div className="section-content">
      <div className="section-flex">
      <div className="section-braket1">「</div>
      <div className="section-text">
      <div className="section-text1">デザインを実装し、生み出す。</div>
      </div>
      <div className="section-braket2">」</div>
      </div>
      </div>
      <Line.Down>
      <div className="line-action-down-programming"></div>
      </Line.Down>
      <Text.Left>
      <div className="line-text-down-programming">スピーディな制作を実現</div>
      </Text.Left>
      </div>
      </FadeIn.Up>
      </div>


      <div className="section-page">
      <div className="section-title">
      <div className="number-title">03</div>
      <div className="text-title">完成</div>
      </div>
      <FadeIn.Up>
      <div className="section-main">
      {this.state.device =="sp" && (
      <img className="section-image" src={completeM} />
      )}
      {this.state.device =="pc" && (
      <img className="section-image" src={complete} />
      )}
      <div className="section-content-complete">
      <div className="section-flex">
      <div className="section-braket1">「</div>
      <div className="section-text-complete">
      <div className="section-text1">さあ、準備は良いですか？</div>
      </div>
      <div className="section-braket2">」</div>
      </div>
      </div>
      <div className="section-sub-content">
      <div>世の中に上映された</div>
      <div>ホームページを一緒に鑑賞しましょう！！</div>
      </div>
      </div>
      </FadeIn.Up>
      <div className="catch-complete">上映作品</div>

      <FadeIn.Up>
      <div className="hp-list">

      <div className="hp-position">
      <img className="hp-image" src={kugayamabbc} />
      <div className="hp-content">
      <div className="hp-text">國學院久我山高校</div>
      <div className="hp-text">男子バスケットボール部</div>
      <a href="https://kugayama-bbc.com/" className="button-link">視聴する</a>
      </div>
      </div>

      <div className="hp-position">
      <img className="hp-image" src={kugayamargb} />
      <div className="hp-content">
      <div className="hp-text">國學院久我山高校</div>
      <div className="hp-text">男子ラグビー部</div>
      <a href="https://kugayama-rugby.com/" className="button-link">視聴する</a>
      </div>
      </div>

      <div className="hp-position">
      <img className="hp-image" src={teikyobbc} />
      <div className="hp-content">
      <div className="hp-text">帝京高校</div>
      <div className="hp-text">男子バスケットボール部</div>
      <a href="https://teikyo-bbc.com/" className="button-link">視聴する</a>
      </div>
      </div>

      </div>
      </FadeIn.Up>

      <FadeIn.Up>
      <div className="message-main">
      <div className="message-text">最高のホームページを</div>
      <div className="message-text">驚きの低価格と、驚きのスピードで</div>
      </div>
      <div className="message-line">
      <a href="https://lin.ee/Do3DuFo" class="btn btn-c btn--yellow btn--cubic"><img className="top-line" alt="line" src={line} />ご相談はこちらから
      </a>
      </div>
      </FadeIn.Up>

      </div>

      <div className="copyright">
  Copyright ©　マーキーズデザインスタジオ, All Rights Reserved.
      </div>
      </div>
    );
  }
}

export default Header;
